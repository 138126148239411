<!-- 有序充电关联电站 -->

<template>
    <div class="associated-station-dialog">
        <w-dialog ref="stationRef" title="关联电站" width="60%" top="6vh" :confirmText="'确认关联'" @wConfirm="onConfirmBtn">
            <div class="flex s-m-b">
                <el-input v-model="searchStation" placeholder="请输入电站名称搜索" clearable :prefix-icon="Search"
                    class="cell-30">
                </el-input>
                <el-button type="primary" @click="getUnboundStationList" class="search-station">搜索</el-button>
            </div>
            <el-table ref="tableref" :data="tableDatas" stripe style="width: 100%" element-loading-text="数据加载中"
                tooltip-effect="light" @selection-change="stationSelectionChange" height='500'
                :row-key="getStationKeys">
                <el-table-column type="selection" width="55" :reserve-selection="true" />
                <el-table-column prop="s_name" label="电站名称" show-overflow-tooltip />
                <el-table-column prop="s_no" label="电站编号" show-overflow-tooltip />
                <el-table-column prop="city_name" label="区域" show-overflow-tooltip />
                <el-table-column prop="equipment_count" label="充电桩总数" show-overflow-tooltip />
                <el-table-column prop="equipment_count_fast" label="快充数量" show-overflow-tooltip />
                <el-table-column prop="equipment_count_slow" label="慢充数量" show-overflow-tooltip />
                <el-table-column prop="s_busine_hours" label="营业时间" show-overflow-tooltip />
                <el-table-column prop="mi_name" label="上线明细" show-overflow-tooltip />
                <el-table-column prop="s_hk_park_code" label="关联停车场" show-overflow-tooltip />
            </el-table>
        </w-dialog>
    </div>
</template>

<script>
import { ref, } from "vue";
import { PowerStations, } from "@/plugins/api.js";
import { ElMessage, } from "element-plus";
import { Search, } from "@element-plus/icons-vue";

export default {
    emits: ["submit",],
    components: {},
    setup(props, { emit }) {
        // 弹框对象
        const stationRef = ref(null);
        const tableDatas = ref([]);  // 关联电站数据
        const selectStation = ref([]); // 选中绑定电站数据
        const searchStation = ref('');  // 搜索电站
        const currentRow = ref(null);  // 当前操作行数据
        /**
         * 
         * 打开弹框
         * 
         * */
        const openDialog = (row) => {
            currentRow.value = row;
            getUnboundStationList();
        };
        /**
         * 
         * 获取电站数据
         * 
         * */
        const getUnboundStationList = () => {
            PowerStations.getUnboundStationList({ keywords: searchStation.value, }).then((res) => {
                if (res.Code === 200) {
                    tableDatas.value = res.Data.list ? res.Data.list : [];
                    stationRef.value.show();
                } else {
                    ElMessage.error(res.Message);
                }
            });
        }
        const getStationKeys = (row) => {
            return row.s_id;
        }
        /**
         * 
         * 选择绑定电站
         * 
         * */
        const stationSelectionChange = (val) => {
            selectStation.value = val;
        }
        /**
         * 
         * 确定按钮
         * 
         * */
        const onConfirmBtn = () => {
            let arr = [];
            if (selectStation.value.length == 0) {
                ElMessage.error('请选择电站！');
                return false;
            } else {
                selectStation.value.forEach(item => {
                    arr.push(item.s_id);
                })
            }
            let params = {
                oc_id: currentRow.value.oc_id,
                oc_s_id: arr.join(','),
            };
            PowerStations.addOrderlyBind(params).then((res) => {
                if (res.Code === 200) {
                    ElMessage.success('关联电站成功！');
                    emit("submit",);
                    stationRef.value.close();
                } else {
                    ElMessage.error(res.Message);
                }
            });
        }

        return {
            openDialog,
            PowerStations,
            stationRef,
            currentRow,
            getUnboundStationList,
            tableDatas,
            selectStation,
            searchStation,
            onConfirmBtn,
            getStationKeys,
            stationSelectionChange,
            Search,
        };
    },
};
</script>

<style lang="scss">
.associated-station-dialog {
    .el-dialog__body {
        height: 680px;
        overflow-y: auto;
    }

    .el-input__inner {
        background-color: #FFFFFF;
        border: 1px solid #CDCFD3;
    }

    .search-station {
        width: 80px;
        margin-left: 10px;
    }
}
</style>