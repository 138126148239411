<!-- 有序充电关联电站 -->

<template>
    <div class="station-info-dialog">
        <w-dialog ref="dialogRef" title="关联电站" width="60%" top="6vh" :confirmText="'确认关联'" :hideFooter="true">
            <el-row :gutter="30">
                <el-col :span="12" v-for="(item, i) in currentRow.station_list" :key="i">
                    <div class="flex align-center s-m-b station-info-content">
                        <img class="s-m-r " src="@/assets/img/powerStation.png" />
                        <div class="text-hidden">{{ item.s_name }}</div>
                    </div>
                </el-col>
            </el-row>
        </w-dialog>
    </div>
</template>

<script>
import { ref, } from "vue";

export default {
    emits: ["submit", "onUnbind",],
    components: {},
    setup() {
        const dialogRef = ref(null);  // 弹框对象
        const currentRow = ref(null);  // 当前操作行数据
        /**
         * 
         * 打开弹框
         * 
         * */
        const openDialog = (row) => {
            currentRow.value = row;
            dialogRef.value.show();
        };

        return {
            dialogRef,
            openDialog,
            currentRow,
        };
    },
};
</script>

<style lang="scss">
.station-info-dialog {
    .el-dialog__body {
        height: 680px;
        overflow-y: auto;
    }

    .station-info-content {
        border-radius: 8px;
        background-color: #F3F4F8;
        font-size: 18px;
        color: #262C30;
        font-weight: bold;
        height: 74px;
        padding: 0 15px;
    }
}
</style>