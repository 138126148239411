<!-- 订单详情 -->

<template>
    <div class="prder-details">
        <w-dialog ref="dialogRef" class="add-dialog" title="详情" width="55%" btnWidth="140px" top="5vh"
            :hideFooter="true">
            <el-row :gutter="20">
                <el-col :span="15">
                    <div class="flex width-full bg-color s-border-radius order-left">
                        <div>
                            <img class="s-m-r" :src="orderDetails.orw_status == '4' ? chargingGreen : chargingBlue"
                                style="width: 100px;height: 100px;" />
                            <div class="text-center s-m-r"
                                :class="orderDetails.orw_status == '4' ? 'title-num-green' : 'title-num-blue'">
                                {{ orderDetails.orw_status_text }}</div>
                        </div>
                        <div class="width-full">
                            <div>
                                <p class="s-m-b f-w-bold">
                                    {{ orderDetails.s_name }}-{{ orderDetails.e_name }}-{{ orderDetails.c_name }}</p>
                                <p>电站编号：{{ orderDetails.s_no }}</p>
                            </div>

                            <el-divider border-style="dashed" />

                            <div class="flex-btw">
								<div>
								    <div class="s-m-b text-gray-color">车牌号</div>
								    <div>{{ orderDetails.mc_plate_no }}</div>
								</div>
                                <div @click="onClickUser">
                                    <div class="s-m-b text-gray-color">用户名称</div>
                                    <div class="cursor-pointer" style="color: #0072F6;">{{ orderDetails.m_username }}</div>
                                </div>
                                <div>
                                    <div class="s-m-b text-gray-color">用户手机</div>
                                    <div>{{ orderDetails.m_mobile }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex-btw charging-state">
                        <div class="flex bg-color s-border-radius charged">
                            <img class="s-m-r charging-img" src="@/assets/img/charged.png" />
                            <div>
                                <div class="s-m-b">已充电量(度)</div>
                                <div class="title-num-blue">{{ orderDetails.orw_total_power }}</div>
                            </div>
                        </div>
                        <div class="flex bg-color s-border-radius charged">
                            <img class="s-m-r charging-img" src="@/assets/img/duration.png" />
                            <div>
                                <div class="s-m-b">充电时长(分)</div>
                                <div class="title-num-green">{{ orderDetails.charging_duration }}</div>
                            </div>
                        </div>
                        <div class="flex bg-color s-border-radius charged">
                            <img class="s-m-r charging-img" src="@/assets/img/cost.png" />
                            <div>
                                <div class="s-m-b">费用总计(元)</div>
                                <div class="title-num-green">{{ orderDetails.orw_total_money }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="bg-color s-border-radius time-on" v-if="orderDetails.orw_status == '4'">
                        充电开始时间：{{ orderDetails.charging_start_time_second }}
                    </div>
                </el-col>
                <el-col :span="9" class="bg-color order-right">
                    <div>
                        <div class="s-m-b">
                            <span class="text-gray-color">订单编号：</span>
                            <span>{{ orderDetails.orw_no }}</span>
                        </div>
                        <div class="s-m-b">
                            <span class="text-gray-color">支付单号：</span>
                            <span>{{ orderDetails.orp_no }}</span>
                        </div>
                        <div class="s-m-b">
                            <span class="text-gray-color">充电时间：</span>
                            <span>{{ orderDetails.charging_start_time }} 至
                                {{ orderDetails.charging_end_time ? orderDetails.charging_end_time : '--' }}</span>
                        </div>
                        <div class="s-m-b" v-if="orderDetails.orw_status == '4'">
                            <span class="text-gray-color">订单费用：</span>
                            <span>{{ orderDetails.orw_total_money }}</span>
                        </div>
                    </div>

                    <div v-if="orderDetails.orw_status == '4'">
                        <div class="flex-btw">
                            <div>
                                <div class="text-gray-color xs-m-b">电费</div>
                                <div class="text-gray-color">服务费</div>
                            </div>
                            <div class="title-num-green">
                                <div class="xs-m-b">￥{{ orderDetails.orw_elec_money }}</div>
                                <div>￥{{ orderDetails.orw_sevice_money }}</div>
                            </div>
                        </div>
                        
                        <div v-if="orderDetails.total_discount !== '0.00'">
                            <el-divider border-style="dashed" />
                            <div class="xs-m-b flex-btw">
                                <span class="f-w-bold">优惠合计</span>
                                <span class="f-w-bold">-￥{{ orderDetails.total_discount }}</span>
                            </div>
                        </div>
						<div class="flex-btw text-gray-color" v-for="(item,i) in orderDetails.coupon_list" :key="i">
						    <div class="xs-m-b">{{item.coupon_tip}}</div>
						    <div>-￥{{ item.orc_amount }}</div>
						</div>
						<div class="xs-m-b flex-btw text-gray-color" v-if="orderDetails.or_system_reduction_amount != 0">
						    <span >系统减免金额</span>
						    <span>-￥{{ orderDetails.or_system_reduction_amount }}</span>
						</div>

                        <div class="flex-btw text-gray-color" v-if="orderDetails.or_vip_discount_amount !== '0.00'">
                            <div class="xs-m-b">会员折扣</div>
                            <div class="xs-m-b">-￥{{ orderDetails.or_vip_discount_amount }}</div>
                        </div>

                        <el-divider border-style="dashed" />

                        <div>
							<template v-if="orderDetails.or_balance_amount != 0">
								<div class="xs-m-b flex-btw">
								    <span class="f-w-bold">抵扣合计</span>
								    <span class="f-w-bold">-￥{{ orderDetails.or_balance_amount }}</span>
								</div>
								                           
								<div class="xs-m-b flex-btw text-gray-color">
								    <span >余额抵扣</span>
								    <span>-￥{{ orderDetails.or_balance_amount }}</span>
								</div>
							</template>
							
                            <div class="xs-m-b flex-end">
                                <span class="f-w-bold">实付金额</span>
                                <span class="title-num-green">￥{{ orderDetails.real_pay_money }}</span>
                            </div>
                        </div>

                        <el-divider border-style="dashed" />

                        <div>
                            <div v-if="orderDetails.or_type == '1'">
                                <div class="xs-m-b">
                                    <span class="text-gray-color">预付金额：</span>
                                    <span>￥{{ orderDetails.or_money }}</span>
                                </div>
                                <div class="xs-m-b">
                                    <span class="text-gray-color">实付金额：</span>
                                    <span>￥{{ orderDetails.real_pay_money }}</span>
                                </div>
                                <div class="xs-m-b">
                                    <span class="text-gray-color">退款金额：</span>
                                    <span>￥{{ orderDetails.or_refund_money }}</span>
                                </div>
                            </div>
                            <div class="xs-m-b">
                                <span class="text-gray-color">支付时间：</span>
                                <span>{{ orderDetails.pat_time }}</span>
                            </div>
                            <div>
                                <span class="text-gray-color">支付方式：</span>
                                <span>{{ orderDetails.payway ? orderDetails.payway : '--' }}</span>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </w-dialog>
    </div>
</template>

<script>
    import { ref, } from "vue";
    // import { ElMessage, } from "element-plus";
    import chargingBlue from '@/assets/img/charging-blue.png';
    import chargingGreen from '@/assets/img/charging-green.png';

    export default {
        components: {},
        props: {
            orderDetails: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
        emits: ["onClickUser",],
        setup(props, { emit }) {
            const dialogRef = ref(null);  // 弹框对象
            /**
             * 
             * 打开弹框
             * 
             * */
            const openDialog = () => {
                dialogRef.value.show();
            };
            /**
             * 
             * 关闭弹框
             * 
             * */
            const closeDialog = () => {
                dialogRef.value.close();
            };
            /**
             * 
             * 点击用户查看用户订单详情
             * 
             * */
            const onClickUser = () => {
                emit("onClickUser", props.orderDetails);
            }

            return {
                openDialog,
                dialogRef,
                closeDialog,
                chargingBlue,
                chargingGreen,
                onClickUser,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .prder-details {
        .add-dialog {
            .el-dialog__body {
                padding: 20px 20px 30px 30px;
            }
        }

        .bg-color {
            background-color: #F4FDFA;
        }

        .total-paid {
            margin-right: 290px;
            color: #1AC994;
        }

        .order-left {
            margin-bottom: 20px;
            padding: 20px;
        }

        .charging-state {
            margin-bottom: 20px;
        }

        .charged {
            padding: 20px 30px;
        }

        .time-on {
            padding: 22px 20px;
        }

        .order-right {
            padding: 20px 15px !important;
        }

        .charging-img {
            width: 50px;
            height: 50px;
        }
    }
</style>