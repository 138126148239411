const menuToken = {
	state: () => ({
		/** 全局 菜单 权限 */
		menus: [{
			index: 0,
			title: "系统首页",
			path: "/home",
			token: "empty",
			childRoute: [{
				index: 0,
				title: "系统首页",
				path: "/home",
				token: "empty",
			}],
		}, ],
		/** 当前模块 权限列表 */
		moduleTokens: [],
		/** 当前菜单 按钮 权限列表 */
		menuTokens: [],
		/** 当前选择的 模块 index */
		moduleIndex: "",
		/** 当前选择的 菜单 index */
		menuIndex: "",
		/** 当前选择的按钮 菜单 index*/
		btnIndex: "",
	}),
	getters: {
		btnMenus(state) {
			let arr = [];
			const compare = (property) => {
				return function(a, b) {
					var value1 = a[property];
					var value2 = b[property];
					return value1 - value2;
				};
			};
			const pathHash = window.location.hash;
			if (
				pathHash != "#/home" &&
				state.menus[state.moduleIndex] &&
				state.menus[state.moduleIndex].childRoute[state.menuIndex]
			) {

				arr = state.menus[state.moduleIndex].childRoute[
					state.menuIndex
				].childRoute.sort(compare("index"));
			}
			return arr;
		},
	},
	mutations: {
		/** menus 初始化 */
		menuTokenInit(state) {
			state.menus = [{
				title: "系统首页",
				path: "/home",
				token: "empty",
				childRoute: [{
					index: 0,
					title: "系统首页",
					path: "/home",
					token: "empty",
				}],
			}, ];
			state.moduleTokens = [];
			state.menuTokens = [];
			state.moduleIndex = "";
			state.menuIndex = "";
			state.btnIndex = "";
		},
		/** 设置 模块 menus */
		setModuleMenus(state) {
			if (state.moduleTokens.length > 0) {
				const compare = (property) => {
					return function(a, b) {
						var value1 = a[property];
						var value2 = b[property];
						return value1 - value2;
					};
				};
				state.moduleTokens.forEach((item) => {
					switch (item) {
						case "m_cODsQxRGVGpZODNq9FCc9yjvaCc9":
							state.menus.push({
								index: 1,
								title: "新能源",
								path: "/electricity",
								token: item,
								childRoute: [],
							});
							break;
						case "m_7oaDDI1u1TKrU9JUeQjRishRcfQE6J":
							state.menus.push({
								index: 2,
								title: "后台管理",
								path: "/basic",
								token: item,
								childRoute: [],
							});
							break;
					}
				});

				state.menus.sort(compare("index"));
			}
		},
		/** 设置 菜单/按钮 menus */
		setBtnMenus(state) {
			const dataHandle = (menu1, menu2) => {
				let routes = state.menus[state.moduleIndex].childRoute;
				let paths = routes.map((item) => item.path);
				let index = paths.findIndex((item) => item === menu1.path);
				if (index != -1) {
					let childRoutes = routes[index].childRoute;
					let childPaths = childRoutes.map((item) => item.path);
					if (!childPaths.includes(menu2.path)) {
						childRoutes.push(menu2);
					}
				} else {
					menu1.childRoute = [menu2];
					routes.push(menu1);
				}
			};

			if (state.menuTokens.length > 0) {
				state.menuTokens.forEach((item) => {
					switch (item) {
						/** 后台管理 **/
						case "members":
							dataHandle({
								index: 0,
								title: "系统管理",
								path: "/basic/user",
								icon: "icon-xitongguanli-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "用户管理",
								path: "/basic/user/members",
								token: item,
								index: 0,
							});
							break;
						case "permissioGroup":
							dataHandle({
								index: 0,
								title: "系统管理",
								path: "/basic/user",
								icon: "icon-xitongguanli-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "权限分组",
								path: "/basic/user/permissioGroup",
								token: item,
								index: 1,
							});
							break;
						case "permissionList":
							dataHandle({
								index: 0,
								title: "系统管理",
								path: "/basic/user",
								icon: "icon-xitongguanli-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "权限列表",
								path: "/basic/user/permissionList",
								token: item,
								index: 2,
							});
							break;
						case "payment":
							dataHandle({
								index: 0,
								title: "系统管理",
								path: "/basic/user",
								icon: "icon-xitongguanli-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "支付配置",
								path: "/basic/user/payment",
								token: item,
								index: 3,
							});
							break;
						case "applet":
							dataHandle({
								index: 0,
								title: "系统管理",
								path: "/basic/user",
								icon: "icon-xitongguanli-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "小程序配置",
								path: "/basic/user/applet",
								token: item,
								index: 4,
							});
							break;
						case "supervise":
							dataHandle({
								index: 0,
								title: "系统管理",
								path: "/basic/user",
								icon: "icon-xitongguanli-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "监管配置",
								path: "/basic/user/supervise",
								token: item,
								index: 5,
							});
							break;
							/** 新能源 **/
						case "n_ecVcIhACW1r4dxjUggRXQhHBUdvG":
							dataHandle({
								index: 0,
								title: "电站管理",
								path: "/electricity/powerStation",
								icon: "icon-dianzhanguanli-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "电站管理",
								path: "/electricity/powerStation/powerManage",
								token: item,
								index: 0,
							});
							break;
						case "n_Al5HJOCcACluMM6PysxPaGfb82gY":
							dataHandle({
								index: 0,
								title: "电站管理",
								path: "/electricity/powerStation",
								icon: "icon-dianzhanguanli-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "充电桩管理",
								path: "/electricity/powerStation/electricPile",
								token: item,
								index: 2,
							});
							break;
						case "n_Baxdtqq9uZh1JilE2DXNJZqiyq8V":
							dataHandle({
								index: 0,
								title: "电站管理",
								path: "/electricity/powerStation",
								icon: "icon-dianzhanguanli-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "设备监控",
								path: "/electricity/powerStation/monitEquip",
								token: item,
								index: 3,
							});
							break;
						case "n_yB2FQWYMHI9YIaI7g4qTHvcyDvlK":
							dataHandle({
								index: 0,
								title: "电站管理",
								path: "/electricity/powerStation",
								icon: "icon-dianzhanguanli-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "私桩管理",
								path: "/electricity/powerStation/privateStake",
								token: item,
								index: 4,
							});
							break;
						case "n_aZPHXvkqBmvIoy34Z3090ZPOc582":
							dataHandle({
								index: 0,
								title: "电站管理",
								path: "/electricity/powerStation",
								icon: "icon-dianzhanguanli-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "有序充电",
								path: "/electricity/powerStation/orderlyCharg",
								token: item,
								index: 5,
							});
							break;
						case "n_Xz3As5d3H8x7n0csdIyDznc88a3b":
							dataHandle({
								index: 2,
								title: "订单管理",
								path: "/electricity/orderManage",
								icon: "icon-dingdanguanli-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "充电订单",
								path: "/electricity/orderManage/chargingOrder",
								token: item,
								index: 0,
							});
							break;
						case "n_y82d073mkGKSBuOFKOgSKhOjRiXs":
							dataHandle({
								index: 2,
								title: "订单管理",
								path: "/electricity/orderManage",
								icon: "icon-dingdanguanli-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "预存订单",
								path: "/electricity/orderManage/prestoreOrder",
								token: item,
								index: 1,
							});
							break;

						case "n_exaSCXDYeLBJBxJID1i2dVrPhQNr":
							dataHandle({
								index: 2,
								title: "订单管理",
								path: "/electricity/orderManage",
								icon: "icon-dingdanguanli-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "平台续期",
								path: "/electricity/orderManage/renewOrder",
								token: item,
								index: 2,
							});
							break;

						case "n_IcHwrERoOEQTDqaN3DAU6QJOPc":
							dataHandle({
								index: 2,
								title: "订单管理",
								path: "/electricity/orderManage",
								icon: "icon-dingdanguanli-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "会员月卡",
								path: "/electricity/orderManage/monthCard",
								token: item,
								index: 3,
							});
							break;

						case "n_wsnN43LKdM4Ep6Z7xA7zx2XUIo7l":
							dataHandle({
								index: 2,
								title: "订单管理",
								path: "/electricity/orderManage",
								icon: "icon-dingdanguanli-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "开票申请",
								path: "/electricity/orderManage/invoicing",
								token: item,
								index: 4,
							});
							break;
						case "n_oXGtfDBL5Fnxbpg45Y73Buy4OWIt":
							dataHandle({
								index: 3,
								title: "会员管理",
								path: "/electricity/memberManage",
								icon: "icon-huiyuanguanli",
								token: "empty",
								childRoute: [],
							}, {
								title: "会员管理",
								path: "/electricity/memberManage/member",
								token: item,
								index: 0,
							});
							break;
						case "n_zGVWX5Mxbee30FmrbPXHfN8dVw":
							dataHandle({
								index: 3,
								title: "会员管理",
								path: "/electricity/memberManage",
								icon: "icon-huiyuanguanli",
								token: "empty",
								childRoute: [],
							}, {
								title: "会员月卡",
								path: "/electricity/memberManage/monthlyCard",
								token: item,
								index: 1,
							});
							break;
						case "n_tRXW4ts9gDckDnH5HpBF4spB8NYO":
							dataHandle({
								index: 4,
								title: "优惠管理",
								path: "/electricity/couponsManage",
								icon: "icon-youhuiguanli",
								token: "empty",
								childRoute: [],
							}, {
								title: "优惠券",
								path: "/electricity/couponsManage/coupons",
								token: item,
								index: 0,
							});
							break;
						case "n_DuHMJOsIEbYTmqMfjH26YeItZu3j":
							dataHandle({
								index: 5,
								title: "通知公告",
								path: "/electricity/notificaAnnounc",
								icon: "icon-a-zu4108",
								token: "empty",
								childRoute: [],
							}, {
								title: "通知公告",
								path: "/electricity/notificaAnnounc/notice",
								token: item,
								index: 0,
							});
							break;
						case "n_hJsLu8tbjBCvznQjvnJykWEYuTSI":
							dataHandle({
								index: 6,
								title: "活动管理",
								path: "/electricity/activity",
								icon: "icon-a-zu5643",
								token: "empty",
								childRoute: [],
							}, {
								title: "活动管理",
								path: "/electricity/activity/activityManage",
								token: item,
								index: 0,
							});
							break;
						case "n_ZsOwIbwsMKQPXXvTGoqnQPCvkwCw":
							dataHandle({
								index: 7,
								title: "积分管理",
								path: "/electricity/points",
								icon: "icon-shouyinweixuanzhong-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "积分商城",
								path: "/electricity/points/pointsManage",
								token: item,
								index: 0,
							});
							break;
						case "n_fwjbKYiWUMm2Olbivny5MzDKYD8m":
							dataHandle({
								index: 7,
								title: "积分管理",
								path: "/electricity/points",
								icon: "icon-huiyuanguanli",
								token: "empty",
								childRoute: [],
							}, {
								title: "积分订单",
								path: "/electricity/points/pointsOrder",
								token: item,
								index: 1,
							});
							break;
						case "n_L4noUueLhZyBSLUaMEgKIpNb4m":
							dataHandle({
								index: 8,
								title: "首页轮播",
								path: "/electricity/carousel",
								icon: "icon-shouyinweixuanzhong-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "首页轮播",
								path: "/electricity/carousel/homeCarousel",
								token: item,
								index: 0,
							});
							break;
					}
				});
			}
			const compare = (property) => {
				return function(a, b) {
					var value1 = a[property];
					var value2 = b[property];
					return value1 - value2;
				};
			};
			state.menus[state.moduleIndex].childRoute.sort(compare("index"));
		},
		/** 设置模块权限列表 */
		setModuleTokens(state, data) {
			state.moduleTokens = data;
		},
		/** 设置菜单、按钮权限列表 */
		setMenuTokens(state, data) {
			state.menuTokens = data;
		},
		/** 判断token是否在菜单/按钮权限列表 */
		menuTokenCheck(state, token) {
			return state.menuTokens.includes(token) || token === "empty";
		},
		/** 设置当前选择的 模块  */
		setModuleIndex(state, index) {
			state.moduleIndex = index;
		},
		/** 设置当前选择的 菜单  */
		setMenuIndex(state, index) {
			state.menuIndex = index;
		},
		/** 设置当前选择的按钮 菜单 */
		setBtnIndex(state, index) {
			state.btnIndex = index;
		},
	},
	actions: {},
};

export default menuToken;