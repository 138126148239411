<!-- 有序充电规则详情 -->

<template>
    <div class="ordered-rules-details">
        <w-dialog ref="dialogRef" title="有序充电规则" width="60%" top="6vh" :confirmText="'编辑规则'" @wConfirm="onConfirmBtn"
            :hideFooter="!(authData.indexOf('n_4J4gQT4qDGgIdpreoB3GPXkUyFaZ') != -1)">
            <div>
                <w-title :title_name="'基本信息'"></w-title>
                <el-row>
                    <el-col :span="12" class="col">
                        <div class="text-grey">规则名称：</div>
                        <div class="contents">{{ infoDatas.oc_name }}</div>
                    </el-col>
                    <el-col :span="12" class="col">
                        <div class="text-grey">限额方式：</div>
                        <div class="contents">{{ infoDatas.oc_type_text }}</div>
                    </el-col>
                    <el-col :span="12" class="col" v-if="infoDatas.oc_type == '2'">
                        <div class="text-grey">功率限额：</div>
                        <div class="contents">{{ infoDatas.oc_power }}kw</div>
                    </el-col>
                </el-row>
            </div>
            <div v-if="infoDatas.oc_type == '1'">
                <w-title :title_name="'分时功率限额'"></w-title>
                <common-table ref="powerTable" tableHeight="250px" :ischeck="false" :ispaging="false"
                    :tableData="infoDatas.list" :columns="powerColumns">
                </common-table>
            </div>
            <div>
                <w-title :title_name="'关联电站明细'"></w-title>
                <common-table ref="stationTable" tableHeight="250px" :ischeck="false" :ispaging="false"
                    :tableData="infoDatas.station_list" :columns="stationColumns" @onUnbind="onUnbind">
                </common-table>
            </div>
        </w-dialog>
    </div>
</template>

<script>
import { ref, nextTick, computed, watch, } from "vue";
import { PowerStations, } from "@/plugins/api.js";
import { ElMessage, } from "element-plus";
import { useStore } from "vuex";

export default {
    emits: ["submit", "onUnbind",],
    components: {},
    setup(props, { emit }) {
        const store = useStore();
        const menuTokens = computed(() => store.state.menuToken.menuTokens);
        const authData = ref([]);
        watch(
            () => menuTokens.value,
            (data) => {
                if (data.length) {
                    authData.value = data;
                }
            },
            {
                deep: true,
                immediate: true,
            }
        );
        // 弹框对象
        const dialogRef = ref(null);
        const infoDatas = ref({});  // 详情数据
        const currentRow = ref(null);  // 当前操作行数据
        // 关联电站表格对象
        const stationTable = ref(null);
        // 关联电站表格配置
        const stationColumns = ref([
            {
                prop: "s_id",
                label: "序号",
                showTooltip: true,
            },
            {
                prop: "s_name",
                label: "电站名称",
                showTooltip: true,
            },
            {
                type: "operation",
                prop: "",
                label: "操作",
                minWidth: 100,
                bottons: [
                    {
                        name: "解绑",
                        action: "onUnbind",
                        className: "error-font-btn",
                        token: "n_u8XVqOTDC5wtn1hdMDdG5XBcB0k2",
                    },
                ],
            },
        ]);
        const powerTable = ref(null);  // 分时功率表格对象
        // 分时功率表格配置
        const powerColumns = ref([
            {
                prop: "ocl_id",
                label: "序号",
                showTooltip: true,
            },
            {
                type: "connect",
                prop: "ocl_stime",
                prop2: "ocl_etime",
                connectText: "-",
                label: "时段",
                showTooltip: true,
            },
            {
                prop: "ocl_power",
                label: "充电站功率限额(kw)",
                showTooltip: true,
            },
        ]);
        /**
         * 
         * 打开弹框
         * 
         * */
        const openDialog = (row) => {
            currentRow.value = row;
            getOrderlyDetail();
        };
        /**
         * 
         * 获取详情
         * 
         * */
        const getOrderlyDetail = () => {
            PowerStations.getOrderlyDetail({ oc_id: currentRow.value.oc_id }).then((res) => {
                if (res.Code === 200) {
                    dialogRef.value.show();
                    infoDatas.value = res.Data;
                    nextTick(() => {
                        stationTable.value.tableLoad();
                        if (res.Data.oc_type == '1') {
                            powerTable.value.tableLoad();
                        }
                    });
                } else {
                    ElMessage.error(res.Message);
                }
            });
        }
        /**
         * 
         * 解绑
         * 
         * */
        const onUnbind = (row) => {
            let params = {
                oc_id: infoDatas.value.oc_id,
                s_id: row.s_id,
            };
            emit("onUnbind", params);
        }
        /**
         * 
         * 编辑规则按钮
         * 
         * */
        const onConfirmBtn = () => {
            emit("submit", infoDatas.value);
        }

        return {
            store,
            menuTokens,
            authData,
            openDialog,
            stationTable,
            stationColumns,
            PowerStations,
            dialogRef,
            infoDatas,
            powerTable,
            powerColumns,
            onUnbind,
            currentRow,
            onConfirmBtn,
            getOrderlyDetail,
        };
    },
};
</script>

<style lang="scss">
.ordered-rules-details {
    .el-dialog__body {
        height: 680px;
        overflow-y: auto;
    }

    .text-grey {
        color: #92979E;
    }

    .el-row {
        border: none !important;
    }

    .col {
        display: flex;
        align-items: center;
        color: #262C30;
    }

    .contents {
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        color: var(--text-color);
    }
}
</style>