<!-- 公用表格组件 -->

<template>
	<div class="common-table">
		<div class="select-wp">
			<div class="select-left">
				<slot name="custom"></slot>
				<div class="select-item" v-for="(item, index) in filters" :key="index">
					<el-select v-model="item.value" :placeholder="item.placeholder" v-if="item.filterType === 'select'"
						:clearable="!item.noclear" @change="selectChange(item)" :multiple="item.isMultiple"
						collapse-tags>
						<el-option v-for="itm in item.options" :key="itm.value"
							:label="item.lab ? itm[item.lab] : itm.label" :value="item.val ? itm[item.val] : itm.value">
						</el-option>
					</el-select>
					<el-input v-model="item.value" :type="item.type ? item.type : 'text'"
						:placeholder="item.placeholder" v-else-if="item.filterType === 'input'"
						@change="tableLoad"></el-input>
					<el-date-picker v-model="item.value" :type="item.type ? item.type : 'date'"
						:placeholder="item.placeholder" value-format="YYYY-MM-DD"
						:range-separator="item.rangeText ? item.rangeText : '至'"
						:start-placeholder="item.startText ? item.startText : '开始日期'"
						:end-placeholder="item.endText ? item.endText : '结束日期'" v-else-if="item.filterType === 'date'"
						@change="tableLoad">
					</el-date-picker>
					<el-input clearable class="search-input" v-model="item.value" :placeholder="item.placeholder"
						:suffix-icon="Search" v-else-if="item.filterType === 'search'"
						@input="inputChange(item)"></el-input>
					<!-- @change="tableLoad" -->
				</div>
				<el-dropdown trigger="click" class="group-container" ref="dropdown" v-if="groupFilters.length">
					<el-button>
						组合查询<el-icon :size="20">
							<ArrowDown />
						</el-icon>
					</el-button>
					<template #dropdown>
						<el-dropdown-menu class="group-filter-content">
							<div v-for="(el, index) in groupFilters" :key="index">
								<div v-if="el.filterType == 'select'" class="group-flex">
									<div class="group-title">{{ el.label }}</div>
									<div class="group-item">
										<div @click="selectGroup(el)" :class="!groupParmas[el.name] ? 'active' : ''">
											全部
										</div>
										<div v-for="(el2, index2) in el.options" :key="index2" :class="
                        el2[el.val] == groupParmas[el.name] ? 'active' : ''
                      " @click="selectGroup(el, el2)">
											{{ el2[el.lab] }}
										</div>
									</div>
								</div>
								<div v-if="el.filterType == 'multipleSelect'" class="group-flex">
									<div class="group-title">{{ el.label }}</div>
									<div class="group-item">
										<div @click="selectMultipleGroup(el)" :class="
                        (groupParmas[el.name] &&
                          groupParmas[el.name].length == el.options.length) ||
                        (groupParmas[el.name] && !groupParmas[el.name].length)
                          ? 'active'
                          : ''
                      ">
											全部
										</div>
										<template v-if="!el.hiddenOptions">
											<div v-for="(el2, index2) in el.options" :key="index2" :class="
                          groupParmas[el.name] &&
                          groupParmas[el.name].includes(el2[el.val])
                            ? 'active'
                            : ''
                        " @click="selectMultipleGroup(el, el2)">
												{{ el2[el.lab] }}
											</div>
										</template>
									</div>
								</div>
								<div v-if="el.filterType == 'daterange'" class="group-flex group-date">
									<div class="group-title date-title">{{ el.label }}</div>
									<el-date-picker v-model="groupParmas[el.name]" type="daterange"
										@change="groupDateChange($event, el)"
										:range-separator="el.rangeText ? el.rangeText : '至'" :start-placeholder="
                      el.startText ? el.startText : '开始日期'
                    " :end-placeholder="el.endText ? el.endText : '结束日期'" value-format="YYYY-MM-DD">
									</el-date-picker>
								</div>
								<div v-if="el.filterType == 'partDaterange'" class="group-flex group-date">
									<div class="group-title date-title">{{ el.label }}</div>
									<div class="separate-date">
										<el-date-picker v-model="groupParmas[el.name1]" type="date"
											value-format="YYYY-MM-DD" @change="groupPartDateChange($event, el, 1)"
											:placeholder="el.startText ? el.startText : '开始日期'" />
										<span>至</span>
										<el-date-picker v-model="groupParmas[el.name2]" type="date"
											value-format="YYYY-MM-DD" @change="groupPartDateChange($event, el, 2)"
											:placeholder="el.endText ? el.endText : '结束日期'" />
									</div>
								</div>
							</div>
							<div class="footer-btn">
								<span @click="handleResetGroup">重置条件</span>
								<span class="sure" @click="handleSureGroup">确认筛选</span>
							</div>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
			<div class="select-right">
				<slot name="operate"></slot>
				<!-- <template > -->
				<div class="icon-btn">
					<template v-if="needExport">
						<el-tooltip class="item" effect="dark" content="导出" placement="left">
							<span class="border-icon" @click="emit('export', datas)">
								<i class="iconfont icon-a-lujing207"></i></span>
						</el-tooltip>
					</template>
					<template v-if="needPrint">
						<el-tooltip class="item" effect="dark" content="打印" placement="left">
							<span class="border-icon" @click="emit('print', datas)">
								<i class="iconfont icon-a-lujing206"></i></span>
						</el-tooltip>
					</template>
				</div>
				<!-- </template> -->
			</div>
		</div>
		<div class="table-wp">
			<el-table :data="datas" stripe :height="tableHeight" style="width: 100%" v-loading="isLoading"
				element-loading-text="数据加载中" :empty-text="emptyText" tooltip-effect="light"
				:cell-class-name="cellClassName">
				<el-table-column type="selection" width="55" v-if="ischeck" />
				<el-table-column type="index" width="55" label="序号" v-if="isSerialNum" />

				<template v-for="(item, index) in columns" :key="index">
					<!-- 按钮操作 -->
					<el-table-column :label="item.label" :width="item.minWidth ? item.minWidth : '100'"
						v-if="item.type === 'operation'" fixed="right">
						<template #default="scope">
							<template v-for="(itm, i) in item.bottons" :key="i">
								<slot v-if="itm.type === 'customRender'" :name="itm.action" :scope="scope"
									:row="scope.row"></slot>
								<template v-else>

									<template v-if="itm.HiddenKey">
										<el-button v-if="
                        itm.showValue.indexOf(scope.row[itm.HiddenKey]) != -1 &&
                        authData.indexOf(itm.token) != -1
                          ? true
                          : false
                      " @click="emit(itm.action, scope.row,scope.$index)"
											:class="itm.className ? itm.className : ''">{{ itm.name }}</el-button>
									</template>
									<template v-else>
										<!-- {{authData.indexOf(itm.token) == -1}} -->
										<el-button v-if="authData.indexOf(itm.token) != -1"
											@click="emit(itm.action, scope.row,scope.$index)"
											:class="itm.className ? itm.className : ''">{{ itm.name }}</el-button>
									</template>
								</template>
							</template>
						</template>
					</el-table-column>

					<!-- 开关 -->
					<el-table-column :label="item.label" :min-width="item.minWidth" v-else-if="item.type === 'switch'">
						<template #default="scope">
							<template v-if="authData.indexOf(item.token) == -1">
								<el-switch v-model="scope.row[item.prop]" inline-prompt
									:active-text="item.activeText ? item.activeText : '开'"
									:inactive-text="item.inactiveText ? item.inactiveText : '关'" :width="45"
									:active-value="1" :inactive-value="2" :disabled="true" @click="noAuthSwitch">
								</el-switch></template>
							<template v-else>
								<el-popconfirm :title="`确定要${
                    scope.row[item.prop] == 1 ? '关闭' : '开启'
                  }${item.label}吗?`" @confirm="emit('statusChange', scope.row)">
									<template #reference>
										<el-switch v-model="scope.row[item.prop]" inline-prompt
											:active-text="item.activeText ? item.activeText : '开'" :inactive-text="
                        item.inactiveText ? item.inactiveText : '关'
                      " :width="45" :active-value="1" :inactive-value="2" :before-change="() => false" />
									</template> </el-popconfirm></template>
						</template>
					</el-table-column>

					<!-- 图片 -->
					<el-table-column :label="item.label" :min-width="item.minWidth" v-else-if="item.type === 'image'">
						<template #default="scope">
							<template v-if="scope.row[item.prop] instanceof Array">
								<template v-for="(itm, i) in scope.row[item.prop]" :key="i">
									<div class="img-wp" v-if="i < 3">
										<!-- fileUrl +  -->
										<el-image style="width: 100px; height: 100px" :src="itm"
											:preview-src-list="scope.row[item.prop]" fit="cover">
										</el-image>
										<div class="remark" v-if="i === 2">
											<div class="dot">
												<span></span>
												<span></span>
												<span></span>
											</div>
											<div class="num"> +{{ scope.row[item.prop].length - 3 }} </div>
										</div>
									</div>
								</template>
							</template>
							<template v-else>
								<div class="img-wp">
									<el-image style="width: 100px; height: 100px" :src="scope.row[item.prop]"
										:preview-src-list="[scope.row[item.prop]]" fit="cover">
									</el-image>
								</div>
							</template>
						</template>
					</el-table-column>

					<!-- 图册 -->
					<el-table-column :label="item.label" :min-width="item.minWidth" v-else-if="item.type === 'block'">
						<template #default="scope">
							<template v-if="authData.indexOf(item.token) == -1">
								<div class="block no-auth-block" @click="noAuthSwitch">
									{{ scope.row[item.prop] ? scope.row[item.prop] : 0 }}
								</div>
							</template>
							<template v-else>
								<div class="block" @click="
                    emit(item.active ? item.active : 'showAlbum', scope.row)
                  ">
									{{ scope.row[item.prop] ? scope.row[item.prop] : 0 }}
								</div>
							</template>
						</template>
					</el-table-column>

					<!-- 视频预览 -->
					<el-table-column :label="item.label" :width="item.minWidth" :min-width="item.minWidth"
						v-else-if="item.type === 'preview'">
						<template #default="scope">
							<div :class="['preview', scope.row[item.prop] ? 'active' : '']"
								@click="emit('preview', scope.row)">
								<i class="iconfont icon-a-lujing434"></i>
							</div>
						</template>
					</el-table-column>

					<!-- 文字按钮 -->
					<el-table-column :label="item.label" :width="item.minWidth" :min-width="item.minWidth"
						v-else-if="item.type === 'textLink'">
						<template #default="scope">
							<span @click="emit('textLink', scope.row)" class="text-link" :style="{
                  color: item.color
                    ? `var(${item.color})`
                    : 'var(--text-color)',
                }">{{ scope.row[item.prop] }}</span>
						</template>
					</el-table-column>
					<!-- 扫码 -->
					<el-table-column :label="item.label" :width="item.minWidth" :min-width="item.minWidth"
						v-else-if="item.type === 'qrcode'">
						<template #default="scope">
							<div class="qrcode" @click="emit('showQrcode', scope.row)">
								<i class="iconfont icon-a-zu4536"></i>
							</div>
						</template>
					</el-table-column>
					<!-- value转换 label + 变颜色 -->
					<el-table-column v-else-if="item.type === 'option'" :label="item.label" :width="item.minWidth"
						:min-width="item.minWidth">
						<template #default="scope">
							<span :style="{
                  color: item.colorData
                    ? item.colorData[item.metaData.indexOf(scope['row'][item.prop])]
                    : '',
                }">{{ item.metaData[item.metaData.indexOf(scope["row"][item.prop])] }}</span>
						</template>
					</el-table-column>
					<el-table-column v-else-if="item.type === 'arr'" :label="item.label" :width="item.minWidth"
						:min-width="item.minWidth" show-overflow-tooltip>
						<template #default="scope">
							<template v-if="scope['row'][item.prop] && scope['row'][item.prop].length">
								<span class="text-hidden">{{
                  scope["row"][item.prop].join("、")
                }}</span>
							</template>
						</template>
					</el-table-column>
					<!-- 显示数组第一个 -->

					<el-table-column v-else-if="item.type === 'arrOne'" :label="item.label" :width="item.minWidth"
						:min-width="item.minWidth">
						<template #default="scope">
							<span :style="{
                  color: item.color
                    ? `var(${item.color})`
                    : 'var(--text-color)',
                }">
								{{ findArr(scope.row[item.prop]) }}
							</span>
						</template>
					</el-table-column>
					<!-- 对象取字段 -->
					<el-table-column v-else-if="item.type === 'obj'" :label="item.label" :width="item.minWidth"
						:min-width="item.minWidth">
						<template #default="scope">
							<span :style="{
                  color: item.color
                    ? `var(${item.color})`
                    : 'var(--text-color)',
                }">
								{{
                  scope.row[item.prop] && scope.row[item.prop][item.objKey]
                    ? scope.row[item.prop][item.objKey]
                    : ""
                }}
							</span>
						</template>
					</el-table-column>
					<!-- 特殊处理 插槽 -->
					<el-table-column v-else-if="item.type === 'customRender'" :label="item.label" :width="item.minWidth"
						:min-width="item.minWidth">
						<template #default="scope">
							<slot :name="item.prop" :scope="scope" :row="scope.row"></slot>
						</template>
					</el-table-column>

					<!-- 两个字段连接 -->

					<el-table-column v-else-if="item.type === 'connect'" :label="item.label" :width="item.minWidth"
						:min-width="item.minWidth">
						<template #default="scope">
							<span :style="{
                  color: item.color
                    ? `var(${item.color})`
                    : 'var(--text-color)',
                }">
								{{ scope.row[item.prop] }}
								<span v-if="item.prop2">
									<template v-if="item.connectText == '至'">
										{{
                      scope.row[item.prop] == scope.row[item.prop2]
                        ? "当天"
                        : item.connectText + " " + scope.row[item.prop2]
                    }}
									</template>
									<template v-else>
										{{ item.connectText }} {{ scope.row[item.prop2] }}
									</template>
								</span>
							</span>
						</template>
					</el-table-column>
					<!-- 普通文本 -->
					<el-table-column :label="item.label" :min-width="item.minWidth" show-overflow-tooltip v-else>
						<template #default="scope">
							<span :style="{
                  color: item.color
                    ? `var(${item.color})`
                    : 'var(--text-color)',
                }">{{
                  item.prop4 &&
                  scope.row[item.prop] &&
                  scope.row[item.prop][item.prop2] &&
                  scope.row[item.prop][item.prop2][item.prop3] &&
                  scope.row[item.prop][item.prop2][item.prop3][item.prop4]
                    ? scope.row[item.prop][item.prop2][item.prop3][item.prop4]
                    : item.prop3 &&
                      scope.row[item.prop] &&
                      scope.row[item.prop][item.prop2] &&
                      scope.row[item.prop][item.prop2][item.prop3]
                    ? scope.row[item.prop][item.prop2][item.prop3]
                    : item.prop2 &&
                      scope.row[item.prop] &&
                      scope.row[item.prop][item.prop2]
                    ? scope.row[item.prop][item.prop2]
                    : scope.row[item.prop]
                }}</span>
						</template>
					</el-table-column>
				</template>
			</el-table>

			<!-- 合计 -->
			<slot name="amount"></slot>

			<div class="flex padding-top" v-if="ispaging">
				<div class="paging">
					共<span>{{ total }}</span>条
				</div>
				<el-pagination v-model="currentPage" :page-sizes="[20, 50, 100, 200, 500]" :page-size="limit" background
					layout="sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
					@current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script setup>
	import {
		ref,
		computed,
		watch,
		onMounted
	} from "vue";
	import {
		Search,
		ArrowDown
	} from "@element-plus/icons-vue";
	import {
		useStore
	} from "vuex";
	import dayjs from "dayjs";
	import {
		ElMessage
	} from "element-plus";

	const props = defineProps({
		isSerialNum: {
			type: Boolean,
			default: false,
		},
		ischeck: {
			type: Boolean,
			default: false,
		},
		needExport: {
			type: Boolean,
			default: false,
		},
		needPrint: {
			type: Boolean,
			default: false,
		},
		ispaging: {
			type: Boolean,
			default: true,
		},
		apiName: {
			type: Function,
			required: false,
		},
		extraParame: {
			type: Object,
			default () {
				return {};
			},
		},
		tableData: {
			type: Array,
			default () {
				return [];
			},
		},
		filters: {
			type: Array,
			default () {
				return [];
			},
		},
		groupFilters: {
			type: Array,
			default () {
				return [];
			},
		},
		columns: {
			type: Array,
			required: true,
		},
		tableHeight: {
			type: String,
		},
		totalNum: {
			type: [String, Number],
			default: 0,
		},
	});

	const emit = defineEmits();

	const store = useStore();

	function findArr(arr) {
		if (arr && arr.length) {
			for (var i = 0; i < arr.length; i++) {
				if (arr[i]) {
					return arr[i] || "";
				}
			}
		}
	}
	/** 文件访问域名 */
	//const fileUrl = computed(() => store.state.Config.fileUrl);
	//按钮权限
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	watch(
		() => menuTokens.value,
		(data) => {
			if (data.length) {
				authData.value = data;
			}
		}, {
			deep: true,
			immediate: true,
		}
	);

	function noAuthSwitch() {
		ElMessage.warning("您没有操作权限！");
	}
	/** 表格数据 */
	const datas = ref([]);
	/** 表格空数据 */
	const emptyText = ref("暂无数据！");
	/** 是否正在获取表格数据 */
	const isLoading = ref(false);
	const dropdown = ref(null);
	/** 获取表格数据 */
	const tableLoad = () => {
		isLoading.value = true;
		let data = {};
		props.filters.length &&
			props.filters.forEach((item) => {
				if (item.filterType === "date" && item.name2) {
					data[item.name] = item.value && item.value[0] ? item.value[0] : "";
					data[item.name2] = item.value && item.value[1] ? item.value[1] : "";
				} else {
					if (item.nameArr && item.nameArr.length) {
						const valueArr = item.value.split("-");
						if (valueArr.length) {
							const chooseItem = item.nameArr.find(
								(el) => el.type == valueArr[0]
							);
							if (chooseItem) {
								data[chooseItem.name] = valueArr[1];
							}
						}
					} else {
						data[item.name] = item.value;
					}
				}
			});
		Object.assign(data, props.extraParame);

		if (props.ispaging) {
			data.limit = limit.value;
			data.page = currentPage.value;
		}
		if (props.groupFilters) {
			const lastGroupParmas = JSON.parse(JSON.stringify(groupParmas.value));
			props.groupFilters.forEach((el) => {
				if (el.filterType == "daterange") {
					if (el.valueType == "hmsUnix") {
						const sDate =
							lastGroupParmas[el.name] && lastGroupParmas[el.name][0] ?
							dayjs(lastGroupParmas[el.name][0] + " 00:00:00").unix() :
							"";
						const eDate =
							lastGroupParmas[el.name] && lastGroupParmas[el.name][1] ?
							dayjs(lastGroupParmas[el.name][1] + " 23:59:59").unix() :
							"";
						sDate || eDate ? (lastGroupParmas[el.name] = [sDate, eDate]) : "";
					} else if (el.valueType == "separate") {
						if (el.lastNameArr.length) {
							lastGroupParmas[el.lastNameArr[0]] =
								lastGroupParmas[el.name][0] || "";
							lastGroupParmas[el.lastNameArr[1]] =
								lastGroupParmas[el.name][1] || "";
							delete lastGroupParmas[el.name];
						}
					}
				}
			});
			data = {
				...data,
				...lastGroupParmas
			};
		}
		if (props.tableData && props.tableData.length) {
			datas.value = props.tableData;
			isLoading.value = false;
		} else {
			datas.value = [];
			isLoading.value = false;
		}
		if (props.totalNum) {
			total.value = props.totalNum;
		}
		props.apiName &&
			props
			.apiName(data)
			.then((res) => {
				if (res.Code === 200) {
					datas.value = res.Data.list ? res.Data.list : [];
					total.value = res.Data.count;
					emit('calculateTotal', datas.value);
					emit('throwTotal', res.Data);
					// console.log("列表",data, res.Data);
				} else {
					datas.value = [];
					emptyText.value = res.Message ? res.Message : "数据获取失败！";
				}
				if (props.groupFilters && props.groupFilters.length) {
					dropdown.value.visible = false;
				}
				isLoading.value = false;
			})
			.catch(() => {
				isLoading.value = false;
				emptyText.value = "数据获取失败！";
			});
	};

	/** 表格列类名 回调 */
	const cellClassName = ({
		column
	}) => {
		if (!column.label) return;
		if (
			column.label.includes("图片") ||
			column.label.includes("图册") ||
			column.label.includes("景区入口") ||
			column.label.includes("包含景点") ||
			column.label.includes("关联道闸") ||
			column.label.includes("视频预览") ||
			column.label.includes("扫码查看")
		) {
			return "image";
		} else {
			return "";
		}
	};

	/** 分页总条数 */
	const total = ref(0);
	/** 每页数量 */
	const limit = ref(20);
	/** 当前页码 */
	const currentPage = ref(1);
	/** 每页数量改变处理 */
	const handleSizeChange = (number) => {
		limit.value = number;
		tableLoad();
	};
	/** 页码改变处理 */
	const handleCurrentChange = (number) => {
		currentPage.value = number;
		tableLoad();
	};
	/** 下拉选择更改 */
	const selectChange = (item) => {
		if (item.action) {
			emit(item.action, item);
		} else {
			tableLoad();
		}
	};
	/** 搜索 */
	const inputChange = (item) => {
		if (item.action) {
			emit(item.action, item);
		} else {
			tableLoad();
		}
	}
	/** 组合查询参数 */
	const groupParmas = ref({});
	/** 组合查询-单选择器-下拉选择更改 */
	const selectGroup = (el, el2) => {
		if (!el2) {
			groupParmas.value[el.name] = "";
		} else {
			groupParmas.value[el.name] = el2[el.val];
		}
		if (el.otherOptionsName) {
			/** 组合查询某个选择框的值由另外的选择框决定时
			 * otherOptionsName表示当前选择框所影响的选择框的名字
			 * showOtherOptionsValue表示当前选择框的showOtherOptionsValue值将会影响otherOptionsName选择框
			 */
			props.groupFilters.length &&
				props.groupFilters.forEach((item) => {
					if (item.name == el.otherOptionsName) {
						if (
							groupParmas.value[el.name] &&
							groupParmas.value[el.name] != el.showOtherOptionsValue
						) {
							item.hiddenOptions = true;
						} else {
							item.hiddenOptions = false;
						}
						groupParmas.value[el.otherOptionsName] = [];
					}
				});
		}
	};
	/** 组合查询-可多选选择器-下拉选择更改 */
	const selectMultipleGroup = (el, el2) => {
		if (!el2) {
			const result = [];
			el.options.length &&
				el.options.forEach((el) => {
					result.push(el.value);
				});
			groupParmas.value[el.name] = result;
		} else {
			if (groupParmas.value[el.name].includes(el2[el.val])) {
				const index = groupParmas.value[el.name].findIndex(
					(itm) => itm == el2[el.val]
				);
				groupParmas.value[el.name].splice(index, 1);
			} else {
				groupParmas.value[el.name].push(el2[el.val]);
			}
		}
	};
	/** 组合查询-初始化赋值 */
	onMounted(() => {
		if (props.groupFilters && props.groupFilters.length) {
			const data = JSON.parse(JSON.stringify(props.groupFilters));
			data.forEach((el) => {
				if (el.filterType == "select") {
					groupParmas.value[el.name] = el.value;
				} else if (el.filterType == "multipleSelect") {
					groupParmas.value[el.name] = el.value;
				} else if (el.filterType == "daterange") {
					groupParmas.value[el.name] = [];
				} else if (el.filterType == "partDaterange") {
					groupParmas.value[el.name1] = el.value1;
					groupParmas.value[el.name2] = el.value2;
				}
			});
		}
	});
	/** 组合查询-时间范围更改 */
	function groupDateChange(data, el) {
		if (data && data.length) {
			if (el.name2) {
				groupParmas.value[el.name] = data && data[0] ? data[0] : "";
				groupParmas.value[el.name2] = data && data[1] ? data[1] : "";
			} else {
				groupParmas.value[el.name] = data;
			}
		} else {
			groupParmas.value[el.name] = "";
		}
	}
	/** 组合查询-独立时间范围更改 */
	function groupPartDateChange(data, el, type) {
		if (data && data.length) {
			if (type === 1) {
				//开始时间
				if (
					groupParmas.value[el.name2] &&
					dayjs(groupParmas.value[el.name2]).isBefore(dayjs(data))
				) {
					//如果结束时间有值 且 在开始时间之前
					groupParmas.value[el.name1] = groupParmas.value[el.name2];
					groupParmas.value[el.name2] = data;
				} else {
					groupParmas.value[el.name1] = data;
				}
			} else {
				//结束时间
				if (
					groupParmas.value[el.name1] &&
					dayjs(groupParmas.value[el.name1]).isAfter(dayjs(data))
				) {
					groupParmas.value[el.name2] = groupParmas.value[el.name1];
					groupParmas.value[el.name1] = data;
				} else {
					groupParmas.value[el.name2] = data;
				}
			}
		} else {
			type == 1 ?
				(groupParmas.value[el.name1] = "") :
				(groupParmas.value[el.name2] = "");
		}
	}
	/** 组合查询-开始查询 */
	function handleSureGroup() {
		tableLoad();
	}
	/** 组合查询-重置条件 */
	function handleResetGroup() {
		for (let key in groupParmas.value) {
			groupParmas.value[key] = "";
		}

		props.groupFilters.forEach((item) => {
			if (item.hiddenOptions) {
				item.hiddenOptions = false;
			}
		});
		const data = JSON.parse(JSON.stringify(props.groupFilters));
		data.forEach((item, index) => {
			if (item.filterType === "daterange") {
				groupParmas.value[item.name] = "";
			} else if (item.filterType === "select") {
				groupParmas.value[item.name] = data[index]["value"] ?
					data[index]["value"] :
					"";
			} else if (item.filterType === "multipleSelect") {
				groupParmas.value[item.name] = [];
			} else if (item.filterType === "partDaterange") {
				groupParmas.value[item.name1] = "";
				groupParmas.value[item.name2] = "";
			}
		});
	}
	defineExpose({
		tableLoad,
		datas,
		isLoading,
	});
</script>

<style lang="scss">
	@import './common-table.scss';
</style>